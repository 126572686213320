import "./actors.css";
import seller from "../../../Media/users/seller.png";
import banker from "../../../Media/users/banker.png";
import family from "../../../Media/users/family.png";
import state from "../../../Media/users/state.png";

const Actors = () => {
  return (
    <div className="containerStep2">
      <div className="container-1Step2">
        <img className="imageStep2" src={banker} />
        <span className="textStep2">The banker</span>
      </div>
      <div className="container-2Step2">
        <img className="image-1Step2" src={state} />
        <span className="text-1Step2">The state</span>
      </div>
      <div className="container-3Step2 ">
        <img className="image-2Step2" src={seller} />
        <span className="text-2Step2">The seller</span>
      </div>
      <div className="container-4Step2">
        <img className="image-3Step2" src={family} />
        <span className="text-3Step2">The buyer</span>
      </div>
    </div>
  );
};
export default Actors;