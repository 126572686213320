import "bootstrap/dist/css/bootstrap.min.css";

import "./Home.css";


import Actors from "./actors/actors";
import LeftSign from "./leftSign/leftSign";
import Title from "./title/title";

const Home = () => {

	console.log("register commpoe")

  return (
    <div className="d-flex flex-row main">
		<LeftSign></LeftSign>
		<div className="d-flex flex-column outlinerHomeComposant">
			<Title></Title>
			<Actors></Actors>
		</div>
    </div>
  );
};

export default Home;

