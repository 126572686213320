


import "./title.css";
const Title = () => {
  return (
    <div className="outlinerStep4Title">
      <div className="inlinerStep4Title">
      <span className="textStep4Title">
          Now the seller gets an intersted person in buying his property. {<br/>}{<br/>}
          The seller may have to answer some technical questions risen by the buyer (e.g. the price, furniture included or not, etc.).
        </span>
      </div>
    </div>
  );
};
export default Title;