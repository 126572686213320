import "./Step_9.css";
import family from "../../Media/users/family.png";

import View1 from "./Views/view1";
import View2 from "./Views/view2";
import View3 from "./Views/view3";

import Text1 from "./Views/text/text1";
import Text2 from "./Views/text/text2";
import Text3 from "./Views/text/text3";
import {useSelector} from "react-redux";


const selectViewNumber = state => state.views.viewNumber.value; 


const Step9 = () => {

  const viewNumber = useSelector(selectViewNumber);

  console.log("view number : ",viewNumber);

  let view; 
  let textView;

  if (viewNumber === 1){
    view=<View1></View1>
    textView=<Text1></Text1>
  } else if (viewNumber === 2){
    view=<View2></View2>
    textView=<Text2></Text2>
  } else if (viewNumber === 3){
    view=<View3></View3>
    textView=<Text3></Text3>
  }
  

  return (
      <div className="frame-5Step9 ">
        <div className="cat-absolute-containerStep9 ">
          <div className="flex-containerStep9 ">
            <div className="rectangle-13Step9 ">
              {textView}
            </div>
            <img className="familyStep9" src={family} />
            <span>The buyer</span>
          </div>
          <div className="frame-7Step9">
            
            <button className="rectangle-23Step9">
              <span className="smart-contractStep9">Digital lending Plateform</span>
            </button>
            <div className="rectangle-22Step9"  >
              {view}
      
            </div>
          </div>
        </div>
      </div>
  );
};
export default Step9;