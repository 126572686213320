


import "./title.css";
const Title = () => {
  return (
    <div className="outlinerStep0Title">
      <div className="inlinerStep0Title">
        <span className="textStep0Title">
          In each step of the demo, you will play a role from this list: {<br/>} 
          [the banker, the state, the seller, the buyer] {<br/>} For the next step you need to
          take the seller role.
        </span>
      </div>
    </div>
  );
};
export default Title;