import "./Step_11.css";

import banker from "../../Media/users/banker.png";
import { Button } from "react-bootstrap";
import {useSelector ,useDispatch} from "react-redux";
import { useHistory } from "react-router-dom";
import CheckExample from "./checkList/checkExample";



const selectViewNumber = state => state.views; 

const Step11 = () => {

  
  const state = useSelector(selectViewNumber);
  console.log("state : ", state);
  const viewNumber1 = state.viewTxOne.value; 



	const hisotry = useHistory();
	const handleClick = ()=>{
		hisotry.push("/step12");
	}

  return (
    <div className="frame-5Step11">
      <div className="cat-absolute-containerStep11">
        <div className="flex-containerStep11">
          <div className="rectangle-13Step11">
            <span className="the-seller-will-go-tStep11">
              The banker is now ready to deploy a smart contract to manage the loan.
              {<br/>}{<br/>}Check the contract details before deploying it{<br/>}{<br/>}
            </span>
          </div>
          <img className="familyStep11" src={banker} />
          <span>The banker</span>
        </div>
        <div className="frame-7Step11">

        <div className="rectangle-25Step11">
              <span >
                Before to take the next action please check if your Metamask is ready, enter the Metamask password if necessary, and connect Metamask to your Banker account 
                {<br/>}
              </span>
          </div>

          <div>
          {<br/>}
          {<br/>}
          </div>
        
          <button className="rectangle-23Step11">
            <span className="smart-contractStep11">LOAN Smart Contract</span>
          </button>
          <div className="rectangle-22Step11"  >
            <CheckExample ></CheckExample>
    
          </div>
          {( viewNumber1 === 3  ) ? <Button onClick={handleClick} style={{marginTop : '3%'}}>NEXT</Button> : <div style={{marginTop : '3%'}}></div>}
        </div>
        
      </div>
    </div>
  );
};
export default Step11;