import banker from "../../../Media/users/banker.png";
import { Button } from "react-bootstrap";
import {useHistory } from "react-router-dom";

import ListGroup from 'react-bootstrap/ListGroup';

const View3 =()=>{

    const hisotry = useHistory();
    const handleClick = ()=>{
      hisotry.push("/step10");
    }

    let loanAmount = localStorage.getItem('loanAmount');

    return(          
        <div className="flex-container-1Step9">
            <img className="rectangle-17Step9" src={banker} />
            <div className="flex-container-2Step9">
            <div className="rectangle-18Step9">
                <ListGroup  as="ol" numbered>
                  <ListGroup.Item bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive"}}>Loan amount :</ListGroup.Item>
                  <ListGroup.Item bsPrefix="h" style={{paddingLeft : '2%', marginBottom:'2%' , fontFamily:"cursive", fontSize: "medium"}}> {loanAmount} €</ListGroup.Item>
                  <ListGroup.Item bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive"}}>Interest Rate:</ListGroup.Item>
                  <ListGroup.Item bsPrefix="h" style={{paddingLeft : '2%', marginBottom:'2%', fontFamily:"cursive", fontSize: "medium" }}>0.1%</ListGroup.Item>
                  <ListGroup.Item bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive"}}>Agreement: </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive", fontSize: "medium"}}>LOAN PERIOD : 30 years</ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="h" style={{paddingLeft : '2%' ,fontFamily:"cursive", fontSize: "medium"}}>LENDER’S RIGHTS : Lender may declare the entire unpaid principal balance on this Note and all accrued unpaid interest immediately due, and then Borrower will pay that amount.</ListGroup.Item>
                </ListGroup>
            </div>
            <Button className="next-instance-1Step1" onClick={handleClick}>SUBMIT YOUR INTEREST</Button>
            </div>
        </div>
  )
}

export default View3