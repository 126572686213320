import React, { useState } from 'react';

import Toast from 'react-bootstrap/Toast';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer } from 'react-bootstrap';

import {useSelector,useDispatch} from "react-redux";

const selectViewNumber = state => state.views.viewTxOne.value;

const selectNotif = state => state.views.notifShow1.value;

function Notification1({message, tx, See}) {

  const dispatch = useDispatch();

  const viewNumber = useSelector(selectViewNumber);
  console.log("view number : ",viewNumber);

  const notShow = useSelector(selectNotif);
  console.log(notShow);


  
  const [showA, setShowA] = useState(true);


  const toggleShowA = () => {if(viewNumber === 3) {setShowA(!showA); dispatch({type : 'notif1/show'})}};

  let title;
  let inFrontTitle; 
  let text; 

  if (viewNumber === 2 ){
    title = "Smart contract is working"
    inFrontTitle = <Spinner animation="border" style={{marginLeft:'5px'}}/>
    text= "The smart contract is checking for illegal clauses"
  } else if (viewNumber === 3){
    title = "Smart contract validation is done"
    text = `The smart contract sent you this transaction id as proof from the blockchain : ${tx}`
  }
  return (

        <ToastContainer position="middle-center" style={{marginLeft : "10%"}}>
            <Toast show={See && showA && notShow} onClose={toggleShowA} bg="warning">
            <Toast.Header>
                <strong className="me-auto"> {title}   {inFrontTitle}</strong>
            </Toast.Header>
            <Toast.Body>{text}</Toast.Body>
            </Toast>
        </ToastContainer>
  );
}

export default Notification1;  