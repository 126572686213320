/* Compile And Push To Eth Network */
/* Compile And Push To Eth Network */
import { ethers } from 'ethers';
//import fs

//import Web3 from 'web3';

const fs = require('fs');
//const path = require('path');
//const solc = require('solc');
const Web3 = require('web3');

//const HDWalletProvider = require('@truffle/hdwallet-provider');
//const mnemonic = '<YOUR SEED PHRASE HERE>'; /* YOUR SEED PHRASE ... */
//const providerOrUrl = '<RINKEBY ENDPOINT HERE>' /* RINKEBY ENDPOINT */

//const provider = new HDWalletProvider({ mnemonic, providerOrUrl });
// xx
 
// const provider = new ethers.providers.Web3Provider(window?.ethereum);

// const web3 = new Web3(provider);
// const content = fs.readFileSync('./../smartContract/MyContract.sol', 'utf8'); /* PATH TO CONTRACT */

/*const input = {
  language: 'Solidity',
  sources: {
    'MyContract.sol': { content }
  },
  settings: {
    outputSelection: { '*': { '*': ['*'] } }
  }
};*/

export default async function deploy (){
  

  console.log("Deplov =>");
};

