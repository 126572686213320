import { ethers } from 'ethers';
import abi from "../smartContract/Mortgageabi";
import bytecode from "../smartContract/Mortgagebytecode";



export default async function deploy2 (paramSigner, paramAddBuyer){
  
    const factory = new ethers.ContractFactory( abi , bytecode.bytecode,  paramSigner );
    const contract = await factory.deploy(paramAddBuyer);

    console.log("Deploy Mortgage =>");
    console.log(contract.address); 

    return (contract.address);
};