import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import {abi} from '../smartContract/abi';
import Notification1 from '../notification/notification1';
import Web3 from 'web3';
import { useState } from 'react';

import { ethers } from 'ethers';

import {useSelector ,useDispatch} from "react-redux";
import deploy2 from './deploySale2';
import { strRef, strBck } from "./../../../ref";



const selectViewNumber = state => state.views; 


function CheckExample() {

  const dispatch = useDispatch();

  const state = useSelector(selectViewNumber);
  console.log("state : ", state);
  const viewNumber1 = state.viewTxOne.value; 


  console.log("view number : ",viewNumber1);

  const [tx, setTx] = useState();

  const [loan, setLoan ] = useState();
  const [interest, setInterest] = useState();
  const [periodn, setPeriod] = useState();

  

  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const testContract = new ethers.Contract('0x610bCdB4eA920219F07DFb2821efa99d0B573E25',abi, signer);

  let wmessage = "";

  const handleClick1 = async ()=>{

    dispatch({type : 'txOne/whileTX'});

//    const transaction_payload= await testContract.increment();
    
    const username = localStorage.getItem("userName")
    const response2 = await fetch( strBck + 'readAddBuyer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
    },
      body: JSON.stringify({"usernameparam":username}),
    }) ;

    const resAddBuyer = await (response2.text())
    console.log("TGH 78: Buyer", resAddBuyer )  
    const resAddBanker = await signer.getAddress()  
    const res = await deploy2(signer, resAddBuyer)
    
    

    console.log("TGH4 username:", username);
    
    wmessage = res;
    console.log("TGH6: ", wmessage);

    setTx(res);

    dispatch({type : 'txOne/afterTX'});


    const response = await fetch( strBck + 'updateloansc', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({"username": username, "LoanSC": res}),
    });
  
    const response1 = await fetch( strBck + 'updateAddBanker', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({"username": username, "AddBanker": resAddBanker}),
    });

//    console.log('transaction response : ', transaction_payload);

   

    

  }

  let checkBoolean1;
  let disabledBoolean1;
  let toSee1;
  let ButtonStyle1;
  let ButtonText1;

  if (viewNumber1 === 1 ){
    checkBoolean1 = false;
    disabledBoolean1 = false;
    toSee1 = false;
    ButtonStyle1 = "secondary";
    ButtonText1 = "Deploy Loan Smart Contract";
  } else if ( viewNumber1 === 2){
    checkBoolean1 = false;
    disabledBoolean1 = false;
    toSee1 = true;
    ButtonStyle1 = "secondary";
    ButtonText1 = "Deploy Loan Smart Contract";
  } else if (viewNumber1 === 3){
    checkBoolean1 = true;
    disabledBoolean1 = true;
    toSee1 = true;
    ButtonStyle1 = "secondary";
    ButtonText1 = "Loan Smart Contract Deployed";
  }


  return (
    <div>
    <Form style={{backgroundColor: "white", width:'100%', borderRadius : '12px', padding : '3%', marginBottom : '5%'}}>
               <Notification1 message={wmessage} See={toSee1} tx={tx}></Notification1>
            <Form.Check 
              type="checkbox"
              id="default-checkbox"
              label="Deploying the Loan smart contract, the Bank confirm that the Buyer is qualified to get the Loan"
              checked={true}
            />  
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label><div style={{fontFamily:"cursive"}}>Loan amount €</div></Form.Label>
              <Form.Control type="textarea" placeholder="700,000 €" onChange={(e)=>{setLoan(e.target.value)}}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label><div style={{fontFamily:"cursive"}}>Loan interest rate</div></Form.Label>
              <Form.Control type="textarea" placeholder="0.1%" onChange={(e)=>{setLoan(e.target.value)}} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label><div style={{fontFamily:"cursive"}}>Loan period</div></Form.Label>
              <Form.Control type="textarea" placeholder="30 years" onChange={(e)=>{setLoan(e.target.value)}}/>
            </Form.Group>

    </Form>
    <Button 
              variant={ButtonStyle1}
              style={{marginLeft : '40%'}} 
              onClick={handleClick1} 
              disabled ={disabledBoolean1}>{ButtonText1}</Button>
    </div>
  );
}

export default CheckExample;