
const initialState = {
    views : {
      viewTxOne: {
        value : 1
      },
      viewTxTwo: {
        value : 1
      },
      viewTxThree: {
        value : 1
      },
      notifShow:{
        value: true
      }
    }
}

// Use the initialState as a default value
const appReducer = (state = initialState, action)=>{
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    // Do something here based on the different types of actions
    case 'txOne/beforeTX':
      return{
          views:{
            ...state.views,
            viewTxOne :{
              value : 1
          }
          }
      }
    case 'txOne/whileTX':
        return{
          views : {
            ...state.views,
            viewTxOne :{
              value : 2
          }
          }
        }

    case 'txOne/afterTX':
      return{
          views : {
            ...state.views,
            viewTxOne :{
              value : 3
          }
          }
      }

    case 'txTwo/beforeTX':
      return{
          views:{
            ...state.views,
            viewTxTwo :{
              value : 1
          }
          }
      }
    case 'txTwo/whileTX':
        return{
            views:{
              ...state.views,
              viewTxTwo :{
                value : 2
            }
            }
        }

    case 'txTwo/afterTX':
      return{
          views:{
            ...state.views,
            viewTxTwo :{
              value : 3
          }
          }
      }

      case 'txThree/beforeTX':
        return{
          views :{
            ...state.views,
            viewTxThree :{
              value : 1
          }
          }
        }
      case 'txThree/whileTX':
          return{
            views:{
              ...state.views,
              viewTxThree :{
                value : 2
            }
            }
          }

      case 'txThree/afterTX':
        return{
          views:{
            ...state.views,
            viewTxThree :{
              value : 3
          }
          }
        }

      case 'notif/show':
        return{
            views:{
              ...state.views,
              notifShow :{
                value : false
            }
            }
        }

    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state
  }
}

export default appReducer;
