

import BasicCard from "../Card/Card";

const View2 = ()=>{



    return (
        <div className="flex-container-1Step3 ">
            <div className="rectangle-19Step3 "><BasicCard  title="Bank 1" text="Interest rate : 0.1%"></BasicCard></div>
            <div className="rectangle-20Step3 "><BasicCard isDisabled="true" title="Bank 2" text="Interest rate : 2%"></BasicCard></div>
            <div className="rectangle-21Step3 "><BasicCard isDisabled="true" title="Bank 3" text="Interest rate : 3%" ></BasicCard></div>
        </div>
    )
}

export default View2;