import "./actors.css";
import seller from "../../../Media/users/seller.png";
import banker from "../../../Media/users/banker.png";
import family from "../../../Media/users/family.png";
import state from "../../../Media/users/state.png";



const Actors = () => {
  return (
    <div className="containerStep10">
      <div className="container-1Step10">
        <img className="imageStep10" src={banker} />
        <span className="textStep10">The banker</span>
      </div>
      <div className="container-2Step10">
        <img className="image-1Step10" src={state} />
        <span className="text-1Step10">The state</span>
      </div>
      <div className="container-3Step10 ">
        <img className="image-2Step10" src={seller} />
        <span className="text-2Step10">The seller</span>
      </div>
      <div className="container-4Step10">
        <img className="image-3Step10" src={family} />
        <span className="text-3Step10">The buyer</span>
      </div>
    </div>
  );
};
export default Actors;