
import "bootstrap/dist/css/bootstrap.min.css";
import "./Step_17.css";

import { Link, useHistory } from "react-router-dom";
import Actors from "./actors/actors";
import Title from "./title/title";
import { Button } from "react-bootstrap";
import LeftSign from "../Step_10/leftSign/leftSign";
const Step17 = () => {
	const hisotry = useHistory();
	const handleClick = ()=>{
		hisotry.push("/step11");
	}


  return (
    <div className="d-flex flex-row main confetti">
		<LeftSign></LeftSign>
		<div className="d-flex flex-column outlinerHomeComposant">
			<Title></Title>
			<Actors></Actors>
		</div>
    </div>
  );
};

export default Step17;

