import "./actors.css";
import banker from "../../../Media/users/banker.png";
import family from "../../../Media/users/family.png";

import { Button,  ToastContainer , Toast  } from "react-bootstrap";
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';

import Notification1 from '../notification/notification1';
import Web3 from 'web3';
import { useState } from 'react';

import { ethers } from 'ethers';

import {useSelector ,useDispatch} from "react-redux";
import Notification2 from "../notification/notification2";
import { Link, useHistory } from 'react-router-dom';

import deploy2 from './deploySale2';
import abi from "../smartContract/Mortgageabi";
import { strRef, strBck } from "./../../../ref";

const selectViewNumber = state => state.views; 

const Actors = () => {

  const dispatch = useDispatch();

  const { width, height } = useWindowSize();

  const state = useSelector(selectViewNumber);
  console.log("state : ", state);
  const viewNumber1 = state.viewTxOne.value; 
  const viewNumber2 = state.viewTxTwo.value;
  const notifShow1 = state.notifShow1.value;
  const notifShow2 = state.notifShow2.value;

  console.log("view number : ",viewNumber1);

  const [tx, setTx] = useState();
  const [message, setMessage]= useState();
  

  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  //const testContract = new ethers.Contract('0x610bCdB4eA920219F07DFb2821efa99d0B573E25',abi, signer);

  const hisotry = useHistory();


  const handleClickDeployMortgage = async ()=>{

    dispatch({type : 'txOne/whileTX'});

    const username = localStorage.getItem("userName")

    const response2 = await fetch( strBck + 'readAddBuyer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
    },
      body: JSON.stringify({"usernameparam":username}),
    }) ;

    const resAddBuyer = await (response2.text())
    const res = await deploy2(signer, resAddBuyer)

    setTx(res);

    console.log("TG 478: mortgage sc", res)

    dispatch({type : 'txOne/afterTX'});

    const response1 = await fetch( strBck + 'updateMortgageSc', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({"username": username, "MortgageSC": res}),
    });

    const mortgageContract = new ethers.Contract(res, abi, signer);

    //hisotry.push("/step14");
    
  }

  let checkBoolean1;
  let disabledBoolean1;
  let disabledBoolean2;
  let toSee1;
  let ButtonStyle1;
  let ButtonText1;

  if (viewNumber1 === 1 ){
    checkBoolean1 = false;
    disabledBoolean1 = false;
    disabledBoolean2 = true;
    toSee1 = false;
    ButtonStyle1 = "primary";
    ButtonText1 = "Deploy Mortgage Smart Contract";
  } else if ( viewNumber1 === 2){
    checkBoolean1 = false;
    disabledBoolean1 = true;
    disabledBoolean2 = false;
    toSee1 = true;
    ButtonStyle1 = "primary";
    ButtonText1 = "Deploy Mortgage Smart Contract";
  } else if (viewNumber1 === 3){
    checkBoolean1 = true;
    disabledBoolean1 = true;
    disabledBoolean2 = false;
    toSee1 = true;
    ButtonStyle1 = "light";
    ButtonText1 = "Mortgage Smart Contract Deployed";
  }

  const handleClickNext = async ()=>{

    
    hisotry.push("/step14");

  }

  let checkBoolean2;
  //let disabledBoolean2;
  let toSee2;
  let ButtonStyle2;
  let ButtonText2;

  if (viewNumber2 === 1 ){
    checkBoolean2 = false;
//    disabledBoolean2 = false;
    toSee2 = false;
    ButtonStyle2 = "primary";
    ButtonText2 = "Authorize Mortgage";
  } else if ( viewNumber2 === 2){
    checkBoolean2 = false;
//    disabledBoolean2 = false;
    toSee2 = true;
    ButtonStyle2 = "primary";
    ButtonText2 = "Authorize Mortgage";
  } else if (viewNumber2 === 3){
    checkBoolean2 = true;
//    disabledBoolean2 = true;
    toSee2 = true;
    ButtonStyle2 = "light";
    ButtonText2 = "Mortgage Authorized";
  }


  

  return (
    <div className="containerStep13">
      <Notification1 See={toSee1} tx={tx}></Notification1>
      <div className="container-3Step13 ">
        <img className="image-2Step13" src={banker} />
        <span className="text-2Step13">The Banker</span>
        <Button disabled ={disabledBoolean1} variant={ButtonStyle1} className=".buttonStyleActorStep13" onClick={handleClickDeployMortgage}>{ButtonText1}</Button>
        <hr></hr>
        <Button disabled ={disabledBoolean2} variant={ButtonStyle2} className=".buttonStyleActorStep13" onClick={handleClickNext}>Go to the next step</Button>
      </div>
      
      <Notification2 See={toSee2} tx={tx}></Notification2>

      <div className="container-4Step13">
        <img className="image-3Step13" src={family} />
        <span className="text-3Step13">The buyer</span>
      </div>

      {( viewNumber1 === 3 && viewNumber2 === 3 && !notifShow1 && !notifShow2) ? <div className="containerRightStep13">
      

      </div> : <div className="containerRightStep13"></div>}
    </div>
  );
};
export default Actors;