

const Text1 = ()=>{
    return(
    <span className="the-buyer-go-to-theStep3 ">
        Now the buyer will use a digital lending plateform to ask for a loan.{<br/>}{<br/>}
        The digital plateform will send his request to many banks when automatically will get a response from intersting banks. {<br/>}{<br/>}
        When you finish filling your request, click on SUBMIT
    </span>
    )
}

export default Text1;