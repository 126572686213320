import { ethers } from 'ethers';
import abi from "../smartContract/LoanSCabi";
import bytecode from "../smartContract/LoanSCbytecode";



export default async function deploy2 (paramSigner, paramAddBuyer){
  
    const factory = new ethers.ContractFactory( abi , bytecode.bytecode,  paramSigner );
    const contract = await factory.deploy(10, 10, paramAddBuyer);

    console.log("Deploy2=>");
    console.log(contract.address); 

    return (contract.address);
};