import "./Step_01.css";
import house from "../../Media/assets/house.jpg";
import seller from "../../Media/users/seller.png";
import { Button, ListGroupItem } from "react-bootstrap";

import { Link, useHistory } from "react-router-dom";
import {useSelector ,useDispatch} from "react-redux";
import { useState, useEffect } from 'react';

import ListGroup from 'react-bootstrap/ListGroup';

import abiRegister from './smartContract/abiRegister';

import { ethers } from 'ethers';
import deploy2 from './deploySale2';

import Form from 'react-bootstrap/Form';
import { strRef, strBck } from "../../ref";
import Notification1 from './notification/notification1';


const selectViewNumber = state => state.views; 

let wmessage;

const Step01 = () => {

  const dispatch = useDispatch();
  const [tx, setTx] = useState();
  const [message, setMessage]= useState();

  const [location, setLocation]= useState("Test Ref");

	const hisotry = useHistory();

  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const landRegistryContract = new ethers.Contract('0xe23390d2FF6493103F2188F46C6C8160E0920594',abiRegister, signer);

  const state = useSelector(selectViewNumber);
  console.log("state : ", state);
  const viewNumber1 = state.viewTxOne.value; 

  let toSee1;

  const handleClickNext = ()=>{

		hisotry.push("/step1");

	}

  const handleClickregister = async ()=>{
  
    console.log("view number : ",viewNumber1);

    dispatch({type : 'txOne/whileTX'});

    //const transaction_payload = await landRegistryContract.registerBuilding({strRef});
    
    const transaction_payload = await landRegistryContract.registerBuilding("TGH");

    console.log('transaction response : ', transaction_payload);
    console.log("Signer: ", signer);
    console.log("Provider: ", provider);
    console.log("Window Ethereum: ", window.ethereum);

    setTx(transaction_payload.hash);
    wmessage = transaction_payload.hash;

    dispatch({type : 'txOne/afterTX'});
		
	}

  useEffect ( async () => {

    const username = localStorage.getItem("userName")

    console.log("TGH361")

    const response = await fetch( strBck + 'readLocationRef', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({usernameparam:username}),

      });

    const respRef = await (response.text())    

    console.log("TGH 44 : ", username, " ")
    //setLocation( username + " Loc Ref " +  Math.floor(Math.random() * 100))

    console.log('TG 360: ',respRef)
    setLocation(respRef)

  }, []);

  let checkBoolean1;
  let disabledBtNext;
  let ButtonStyle1;
  let ButtonText1;

  if (viewNumber1 === 1 ){
    checkBoolean1 = false;
    disabledBtNext = true;
    toSee1 = false;
    ButtonStyle1 = "secondary";
    ButtonText1 = "ISSUE";
  } else if ( viewNumber1 === 2){
    checkBoolean1 = false;
    disabledBtNext = true;
    toSee1 = true;
    ButtonStyle1 = "secondary";
    ButtonText1 = "ISSUE";
  } else if (viewNumber1 === 3){
    checkBoolean1 = true;
    disabledBtNext = false;
    toSee1 = true;
    ButtonStyle1 = "secondary";
    ButtonText1 = "ISSUED";
  } 

  return (
    
    <div className="frame-5Step1">
      <div className="cat-absolute-containerStep1">
        <div className="flex-containerStep1">
          <div className="rectangle-13Step1">
            <span className="the-seller-will-go-tStep1">
              The seller will go to a real state market place where he will post
              his advertisement to sell his house.{<br/>}
              {<br/>}Before to start the selling process, the seller needs to check the registration of his house in the Land Registry Smart contract managed by the state{<br/>}{<br/>}
              {<br/>} {<br/>}{<br/>}
            </span>
          </div>
          <img className="sellerStep1" src={seller} />
          <span>The seller</span>
        </div>
        <div className="flex-container2Step1">
          <div className="rectangle-25Step1">
              <span className="the-seller-will-go-tStep1">
                Before to take the next action please check if your Metamask is ready, enter the Metamask password if necessary, and connect Metamask to our Seller account 
                {<br/>}
                
              </span>
          </div>
          <div className="rectangle-15Step1">
          <Notification1 message={wmessage} See={toSee1} tx={tx}></Notification1>
            <div className="rectangle-16Step1">Housing + market place</div>
            <div className="flex-container-1Step1">
              <img className="rectangle-17Step1" src={house} />
              <div className="flex-container-2Step1">
                <div className="rectangle-18Step1">
                  <ListGroup  as="ol" numbered>
                    <ListGroupItem bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive"}}>Reference : </ListGroupItem>
                    <ListGroupItem bsPrefix="h" style={{paddingLeft : '2%', marginBottom:'2%', fontFamily:"cursive", fontSize: "medium" }}>{location}</ListGroupItem>
                    <ListGroup.Item bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive"}}>Price: €700,000 </ListGroup.Item>
                    <ListGroup.Item bsPrefix="h" style={{paddingLeft : '2%', marginBottom:'2%' , fontFamily:"cursive", fontSize: "medium"}}>4 bedroom detached house for sale</ListGroup.Item>
                    <ListGroupItem bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive"}}>Address: </ListGroupItem>
                    <ListGroupItem bsPrefix="h" style={{paddingLeft : '2%', marginBottom:'2%', fontFamily:"cursive", fontSize: "medium" }}>Ings Road, Ulleskelf, Tadcaster, LS24 </ListGroupItem>
                    <ListGroupItem bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive"}}>Description: </ListGroupItem>
                    <ListGroup.Item as="li" bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive", fontSize: "medium"}}>All rooms of excellent proportions</ListGroup.Item>
                    <ListGroup.Item as="li" bsPrefix="h" style={{paddingLeft : '2%' ,fontFamily:"cursive", fontSize: "medium"}}>Impressive reception hall with staircase to galleried landing</ListGroup.Item>
                    <ListGroup.Item as="li" bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive", fontSize: "medium"}}>Two reception rooms and well fitted breakfast kitchen</ListGroup.Item>
                    <ListGroup.Item as="li" bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive", fontSize: "medium"}}>Bedroom one with en-suite dressing room and wet room</ListGroup.Item>
                  </ListGroup>
                </div>
                <div>
                <Form>
                  <Button className="next-instance-1Step1" onClick={handleClickregister}>Check the registration in the Land registry smart contract</Button>
                  <Button button disabled={disabledBtNext} className="next-instance-1Step1" onClick={handleClickNext}>Go to the Next step</Button>
                </Form>
                </div>
              </div>
            </div>
          </div>
        </div>  
      </div>
    </div>
  );
};
export default Step01;