import "./Step_8.css";
import buyer from "../../Media/users/family.png";
import { Button } from "react-bootstrap";
import {useSelector ,useDispatch} from "react-redux";
import { Link, useHistory } from "react-router-dom";
import CheckExample from "./checkList/checkExample";
import saleabi from './smartContract/saleabi';
import { ethers } from 'ethers';
import { strRef, strBck } from "./../../ref";
import Notification2 from './notification/notification2';
import { useState } from 'react';


const selectViewNumber = state => state.views; 

const Step8 = () => {

  const dispatch = useDispatch();
  
  const state = useSelector(selectViewNumber);
  console.log("state : ", state);
  const viewNumber1 = state.viewTxOne.value; 
  const viewNumber2 = state.viewTxTwo.value;
  const viewNumber3 = state.viewTxThree.value;
  const username = localStorage.getItem("userName")
  const [tx, setTx] = useState();

  // A rechanger quand lecheck 2 sera ready 
  // {( viewNumber1 === 3 && viewNumber2 === 3 && viewNumber3 === 3  ) ? <Button onClick={handleClick} style={{marginTop : '3%'}}>BORROW MONEY</Button> : <div style={{marginTop : '3%'}}></div>}

	const hisotry = useHistory();

	const handleClickNext = ()=>{

		hisotry.push("/step9");
	}

  const handleClickOption = async (event) =>{

    console.log("state avant :", state)

    dispatch({type : 'txTwo/whileTX'});

    console.log("state apres :", state)

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const resAddBanker = await signer.getAddress()  

    const response = await fetch( strBck + 'readSaleSC', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({username:username}),

      });

    const respSaleSC = await (response.text())

    const saleContract = new ethers.Contract(respSaleSC, saleabi, signer);
    const transaction_payload= await saleContract.setOption();

    console.log('Step8 transaction response : ', transaction_payload);

    const response1 = await fetch( strBck + 'updateAddBuyer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({"username": username, "AddBuyer": resAddBanker}),
    });

    setTx(transaction_payload.hash);

    dispatch({type : 'txTwo/afterTX'});

	}

 
  let toSee2;
  let ButtonStyle2;
  let ButtonText2;

  if (viewNumber2 === 1 ){
   
    toSee2 = false;
    ButtonStyle2 = "secondary";
    ButtonText2 = "Check Mortgage Registry Smart Contract";
  } else if ( viewNumber2 === 2){
    
    toSee2 = true;
    ButtonStyle2 = "secondary";
    ButtonText2 = "Check Mortgage Registry Smart Contract";
  } else if (viewNumber2 === 3){
    
    toSee2 = true;
    ButtonStyle2 = "light";
    ButtonText2 = "Mortgage Smart Contract Checked";
  }


  return (
    
    <div className="frame-5Step8">
      
      <div className="cat-absolute-containerStep8">
        <div className="flex-containerStep8">
          <div className="rectangle-13Step8">
            <span className="the-seller-will-go-tStep8">
              A smart contract deployed for you on the blockchain to be able to check some details before you buy your house.
              {<br/>}{<br/>}You find a list of the things that the smart contract will help you to verify{<br/>}{<br/>}
            </span>
          </div>
          <img className="familyStep8" src={buyer} />
          <span>The buyer</span>
        </div>
        <div className="frame-7Step8">
        
          
          
          <div className="rectangle-25Step8">
              <span >
                Before to take the next action please check if your Metamask is ready, enter the Metamask password if necessary, and connect Metamask to your Buyer account 
                {<br/>}
              </span>
          </div>

          <div>
          {<br/>}
          {<br/>}
          </div>

          <button className="rectangle-23Step8">
            <span className="smart-contractStep8">Checking Sale Validity Using Smart Contract</span>
          </button>

          <div className="rectangle-22Step8"  >
            <CheckExample ></CheckExample>
            <Notification2 See={toSee2} tx={tx}></Notification2>
          </div>
          
        
          {( viewNumber1 === 3  && viewNumber3 === 3  ) ? <Button onClick={handleClickOption} style={{marginTop : '3%'}}>Mark Interest</Button> : <div style={{marginTop : '3%'}}></div>}
          {( viewNumber1 === 3  && viewNumber3 === 3  ) ? <Button onClick={handleClickNext} style={{marginTop : '3%'}}>Go to the next step</Button> : <div style={{marginTop : '3%'}}></div>}

        </div>
        
      </div>
    </div>
  );
};
export default Step8;