import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';


import "./App.css";

// import the app componnents 
import Home from './Components/Home/Home';
import Login from './Components/Login/Login';
import Register from './Components/Register/Register';
import Step0 from './Components/Step_0/Step_0';
import Step01 from './Components/Step_01/Step_01';
import Step1 from './Components/Step_1/Step_1';
import Step2 from './Components/Step_2/Step_2';
import Step3 from './Components/Step_3/Step_3';
import Step4 from './Components/Step_4/Step_4';
import Step5 from './Components/Step_5/Step_5';
import Step6 from './Components/Step_6/Step_6';
import Step7 from './Components/Step_7/Step_7';
import Step71 from './Components/Step_71/Step_71';
import Step8 from './Components/Step_8/Step_8';
import Step9 from './Components/Step_9/Step_9';
import Step10 from './Components/Step_10/Step_10';
import Step11 from './Components/Step_11/Step_11';
import Step12 from './Components/Step_12/Step_12';
import Step13 from './Components/Step_13/Step_13';
import Step14 from './Components/Step_14/Step_14';
import Step15 from './Components/Step_15/Step_15';
import Step16 from './Components/Step_16/Step_16';
import Step17 from './Components/Step_17/Step_17';

import Error from './Components/Error/Error';
import Next from './Components/Next/Next';



//import the stores
import storeStep1 from './Redux/Step1/storeStep1';
import storeStep01 from './Redux/Step01/storeStep01';
import storeStep3 from './Redux/Step3/storeStep3';
import storeStep5 from './Redux/Step5/storeStep5';
import storeStep7 from './Redux/Step7/storeStep7';
import storeStep71 from './Redux/Step71/storeStep71';
import storeStep8 from './Redux/Step8/storeStep8';
import storeStep9 from './Redux/Step9/storeStep9';
import storeStep11 from './Redux/Step11/storeStep11';
import storeStep12 from './Redux/Step12/storeStep12';
import storeStep13 from './Redux/Step13/storeStep13';
import storeStep14 from './Redux/Step14/storeStep14';
import storeStep15 from './Redux/Step15/storeStep15';
import storeStep16 from './Redux/Step16/storeStep16';
import storeStep17 from './Redux/Step17/storeStep17';

const App = () => {
	console.log("register commpoe")

  return (
		<div >
			<Router>
			  <Route path="/home" exact component={Home} />	
			  <Route path="/" exact component={Register} />
			  <Route path="/login" exact component={Login} />
			  <Route path="/register" exact component={Register} />
			  <Route path="/step0" component={Step0} />
			  <Provider store={storeStep1}><Route path="/step1" component={Step1} /></Provider>
			  <Provider store={storeStep01}><Route path="/step01" component={Step01} /></Provider>
			  <Route path="/step2" component={Step2} />
			  <Provider store={storeStep3}><Route path="/step3" component={Step3} /></Provider>
			  <Route path="/step4" component={Step4} />
			  <Provider store={storeStep5}><Route path="/step5" component={Step5} /></Provider>
			  <Route path="/step6" component={Step6} />
			  <Provider store={storeStep7}><Route path="/step7" component={Step7} /></Provider>
			  <Provider store={storeStep71}><Route path="/step71" component={Step71} /></Provider>
			  <Provider store={storeStep8}><Route path="/step8" component={Step8} /></Provider>
			  <Provider store={storeStep9}><Route path="/step9" component={Step9} /></Provider>
			  <Route path="/step10" component={Step10} />
			  <Provider store={storeStep11}><Route path="/step11" component={Step11} /></Provider>
			  <Provider store={storeStep12}><Route path="/step12" component={Step12} /></Provider>
			  <Provider store={storeStep13}><Route path="/step13" component={Step13} /></Provider>
			  <Provider store={storeStep14}><Route path="/step14" component={Step14} /></Provider>
			  <Provider store={storeStep15}><Route path="/step15" component={Step15} /></Provider>
			  <Provider store={storeStep16}><Route path="/step16" component={Step16} /></Provider>
			  <Provider store={storeStep17}><Route path="/step17" component={Step17} /></Provider>



			  <Route path="/error" component={Error} />
			  <Route path="/next" component={Next} />
			</Router>
		</div>
  );
};

export default App;