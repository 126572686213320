
import "bootstrap/dist/css/bootstrap.min.css";
import "./Step_2.css";

import { Link, useHistory } from "react-router-dom";
import Actors from "./actors/actors";
import LeftSign from "./leftSign/leftSign";
import Title from "./title/title";
import { Button } from "react-bootstrap";
import { useState, useEffect } from 'react';
import { strRef, strBck } from "./../../ref";




const Step2 = () => {


	const hisotry = useHistory();
	const [saleSC, setSaleSC]= useState(" ");
	const [sellerAdd, setSellerAdd]= useState(" ");

	useEffect(async()=>{

		await handleClickUp();

	});


	const handleClick = ()=>{
		hisotry.push("/step3");
	}

	const handleClickUp = async ()=>{ 

		const username = localStorage.getItem("userName")

		console.log("TGH2500 get sc sdd for: ", username);

		const response = await fetch( strBck + 'readSaleSC', {
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json'
			},
			body: JSON.stringify({username:username}),
	  
			});

		const response2 = await fetch( strBck + 'readAddSeller', {
				method: 'POST',
				headers: {
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify({usernameparam:username}),
		  
				}); 	
	  
		  const respSaleSC = await (response.text())
		  const respSellerAdd = await (response2.text())
		
		  console.log("TGH25 sale sc: ", respSaleSC);

		  setSaleSC(respSaleSC);
		  setSellerAdd(respSellerAdd);

	}


	
  return (
    <div className="d-flex flex-row main">
		<LeftSign></LeftSign>
		<div className="d-flex flex-column outlinerHomeComposant">
			<Title></Title>
			<Actors></Actors>
			<Button className="buttonStyleGeneralStep2" onClick={handleClick}>SELECT</Button>
			
			<div>
				{<br/>}
			</div>
			
			<div className="rectangle-25Step2">
				<span className="the-seller-will-go-tStep1">
					Below are listed addresses of all smart contracts created at this state of the process. You can copy any of these addresses and paste it in the search field of the Sepolia etherscan web site (https://sepolia.etherscan.io/) in order to see the details of all corresponding transactions. 
					{<br/>} 
					{<br/>} 
					<b>Address of the sale smart contract: {saleSC}</b>
					{<br/>} 
				</span>
			</div>

		</div>
		
    </div>
	
  );
};

export default Step2;

