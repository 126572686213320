


import "./title.css";
const Title = () => {
  return (
    <div className="outlinerStep4Title">
      <div className="inlinerStep4Title">
      <span className="textStep4Title">
          Now the Banker will deploy the Mortgage Smart Contract.{<br/>}{<br/>}
          
        </span>
      </div>
    </div>
  );
};
export default Title;