import "./Step_5.css";
import house from "../../Media/assets/house.jpg";
import seller from "../../Media/users/seller.png";
import { Button } from "react-bootstrap";

import DismissibleToast from "./toast/toast";
import View1 from "./views/view1";
import View2 from "./views/view2";
import Text1 from "./views/text/text1";
import Text2 from "./views/text/text2";
import Text3 from "./views/text/text3";

import {useSelector} from "react-redux";

const selectViewNumber = state => state.viewNumber.value; 

const Step5 = () => {

  const viewNumber = useSelector(selectViewNumber);
  console.log("view number : ",viewNumber);



  let view;
  let textView;

  if (viewNumber === 1){
    view=<View1></View1>
    textView=<Text1></Text1>
  } else if (viewNumber === 2){
    view=<div></div>
    textView=<Text2></Text2>
  } else if (viewNumber === 3){
    view=<View2></View2>
    textView=<Text3></Text3>
  }

  return (
    <div className="frame-5Step5">
      <div className="cat-absolute-containerStep5">
        <div className="flex-containerStep5">
          <div className="rectangle-13Step5">
            {textView}
          </div>
          <img className="sellerStep5" src={seller} />
          <span>The seller</span>
        </div>
        <div className="rectangle-15Step5">
          <div className="display-notifStep5">
            <div className="rectangle-16Step5">Housing + market place</div>
            <DismissibleToast></DismissibleToast>
          </div>

          <div className="flex-container-1Step5">
            <img className="rectangle-17Step5" src={house} />
            <div className="flex-container-2Step5">
            {view}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Step5;