


import "./title.css";
const Title = () => {
  return (
    <div className="outlinerStep4Title">
      <div className="inlinerStep4Title">
      <span className="textStep4Title">
        The Land Registry can now be updated mentioning the identity of the new owner.{<br/>}{<br/>}
          
        </span>
      </div>
    </div>
  );
};
export default Title;