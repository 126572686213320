
import "bootstrap/dist/css/bootstrap.min.css";
import "./Step_13.css";

import { Link, useHistory } from "react-router-dom";
import Actors from "./actors/actors";
import Title from "./title/title";
import { Button } from "react-bootstrap";
import LeftSign from "../Step_10/leftSign/leftSign";
import { useState, useEffect } from 'react';
import { strRef, strBck } from "./../../ref";



const Step13 = () => {

	const hisotry = useHistory();
	const [saleSC, setSaleSC]= useState(" ");
	const [loanSC, setLoanSC]= useState(" ");
	const [sellerAdd, setSellerAdd]= useState(" ");
	const [buyerAdd, setBuyerAdd]= useState(" ");
	const [bankerAdd, setBankerAdd]= useState(" ");

	useEffect(async()=>{
		await handleClickUp();
	});

	const handleClick = ()=>{
		hisotry.push("/step11");
	}

	const handleClickUp = async ()=>{

		const username = localStorage.getItem("userName")

		const response = await fetch( strBck + 'readSaleSC', {
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json'
			},
			body: JSON.stringify({username:username}),
	  
			});

		const response2 = await fetch( strBck + 'readLoanSC', {
				method: 'POST',
				headers: {
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify({usernameparam:username}),
		  
				}); 	

		const response3 = await fetch( strBck + 'readAddBuyer', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({usernameparam:username}),
		
			}); 	
		
	  
		  const respSaleSC = await (response.text())
		  const respLoanSC = await (response2.text())
		  const respBuyerAdd = await (response3.text())
		
		  setSaleSC(respSaleSC);
		  setLoanSC(respLoanSC);
		  setBuyerAdd(respBuyerAdd);
		
	}

  return (
    <div className="d-flex flex-row main confetti">
		<LeftSign></LeftSign>
		<div className="d-flex flex-column outlinerHomeComposant">
			<div className="rectangle-25Step12">
              <span >
                Before to take the next action please check if your Metamask is ready, enter the Metamask password if necessary, and connect Metamask to your Banker account 
                {<br/>}
              </span>
            </div>

			<div>
				{<br/>}
			</div>

			<Title></Title>
			<Actors></Actors>
			

			<div className="rectangle-25Step2">
				<span className="the-seller-will-go-tStep1">
					Below are listed addresses of all smart contracts created at this state of the process. You can copy any of these addresses and paste it in the search field of the Sepolia etherscan web site (https://sepolia.etherscan.io/) in order to see the details of all corresponding transactions. 
					{<br/>} 
					{<br/>} 
					<b>Address of the SALE Smart Contract: {saleSC}</b>
					{<br/>}
					<b>Address of the LOAN Smart Contract: {loanSC}</b>
				</span>
			</div>
		</div>
    </div>
  );
};

export default Step13;

