
import { useState } from 'react';
import {useHistory } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const View2= ()=>{

    const [sellerMessage, setSellerMessage] = useState("");

    const hisotry = useHistory();

    const handleClick = ()=>{
      localStorage.setItem('sellerMessage', JSON.stringify(sellerMessage));
      hisotry.push("/step6");
    }

    return(
        <Form style={{backgroundColor: "white", width:'82%', borderRadius : '12px', padding : '3%'}}>
  
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label><div style={{ fontFamily:"cursive"}} >Text Message</div></Form.Label>
          <Form.Control  as="textarea" rows={6} placeholder="Put your response to the buyer questions" onChange={(e)=>setSellerMessage(e.target.value)}/>
        </Form.Group>
        <Button variant="primary" type="submit"  onClick={handleClick}>
          SEND
        </Button>
      </Form>
    )
}

export default View2;