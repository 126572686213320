

const initialState = {
    viewNumber: {
        value : 1
    }
  }
  
  // Use the initialState as a default value
const appReducer = (state = initialState, action)=>{
    // The reducer normally looks at the action type field to decide what happens
    switch (action.type) {
      // Do something here based on the different types of actions
      case 'viewStep71/changeView1':
        return{
            viewNumber :{
                value : 1
            }
        }
      case 'viewStep71/changeView2':
          return{
              viewNumber :{
                  value : 2
              }
          }
      default:
        // If this reducer doesn't recognize the action type, or doesn't
        // care about this specific action, return the existing state unchanged
        return state
    }
  }

export default appReducer;