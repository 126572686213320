import "./Step_1.css";
import house from "../../Media/assets/house.jpg";
import seller from "../../Media/users/seller.png";
import { Button, ListGroupItem } from "react-bootstrap";

import { Link, useHistory } from "react-router-dom";
import {useSelector ,useDispatch} from "react-redux";
import { useState, useEffect } from 'react';

import ListGroup from 'react-bootstrap/ListGroup';

import abiRegister from './smartContract/abiRegister';

import { ethers } from 'ethers';
import deploy2 from './deploySale2';

import Form from 'react-bootstrap/Form';
import Notification1 from './notification/notification1';
import { strRef, strBck } from "./../../ref";
import Modal from 'react-bootstrap/Modal';

const selectViewNumber = state => state.views; 

let wmessage;

const Step1 = () => {

  const dispatch = useDispatch();
  const [tx, setTx] = useState();
  const [message, setMessage]= useState();

  const [location, setLocation]= useState("Test Ref");

	const hisotry = useHistory();

  // MODAL

  const [openModal, setOpenModal] = useState(false)

  const handleModal = () => {
  //<Button variant="primary" onClick={handleModal}>Get Informations</Button>  
    setOpenModal(!openModal)
  }
  

  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const landRegistryContract = new ethers.Contract('0xe23390d2FF6493103F2188F46C6C8160E0920594',abiRegister, signer);

  const state = useSelector(selectViewNumber);
  console.log("state : ", state);
  const viewNumber1 = state.viewTxOne.value; 

  
	const handleClickUpload = async ()=>{ 

    dispatch({type : 'txOne/whileTX'});
    
    const res = await deploy2(signer)
    const resAddSeller = await signer.getAddress()
     
    const username = localStorage.getItem("userName")
    wmessage = res.text
    setTx(res);
    
    console.log("TGH 33", res);
    console.log("TGH 3 username: ", username)
    // mise à jour du doc ds Mongodb

    const response1 = await fetch( strBck + 'updateAddSeller', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({"username": username, "AddSeller": resAddSeller}),
    });

    const response = await fetch( strBck + 'updatesalesc', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({"username": username, "SaleSC": res}),
    });

    dispatch({type : 'txOne/afterTX'});

		//hisotry.push("/step2");

	}

  let toSee1;

  const handleClickNext = ()=>{

		hisotry.push("/step2");

	}


  useEffect ( async () => {

    const username = localStorage.getItem("userName")

    console.log("TGH361")

    const response = await fetch( strBck + 'readLocationRef', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({usernameparam:username}),

      });

    const respRef = await (response.text())    

    //console.log("TGH 44 : ", username, " ", Math.floor(Math.random() * 100))
    //setLocation( username + " Loc Ref " +  Math.floor(Math.random() * 100))

    console.log('TG 360: ',respRef)
    setLocation(respRef)
    
  }, []);

  let checkBoolean1;
  let disabledBtNext;
  let ButtonStyle1;
  let ButtonText1;

  if (viewNumber1 === 1 ){
    checkBoolean1 = false;
    disabledBtNext = true;
    toSee1 = false;
    ButtonStyle1 = "secondary";
    ButtonText1 = "ISSUE";
  } else if ( viewNumber1 === 2){
    checkBoolean1 = false;
    disabledBtNext = true;
    toSee1 = true;
    ButtonStyle1 = "secondary";
    ButtonText1 = "ISSUE";
  } else if (viewNumber1 === 3){
    checkBoolean1 = true;
    disabledBtNext = false;
    toSee1 = true;
    ButtonStyle1 = "secondary";
    ButtonText1 = "ISSUED";
  } 

  return (
    
    <div className="frame-5Step1">


      
       <Modal show={openModal} onHide={handleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Title Modal</Modal.Title>
        </Modal.Header>
        <Modal.Body>Message</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModal}>
            Close
          </Button>
      
        </Modal.Footer>
      </Modal>
      <div className="cat-absolute-containerStep1">
        <div className="flex-containerStep1">
          <div className="rectangle-13Step1">
            <span className="the-seller-will-go-tStep1">
              The seller will go to a real state market place where he will post
              his advertisement to sell his house.{<br/>}
              {<br/>}When the seller decide to start the selling process, the Sale smart contract will be automaticaly deployed on the Blockchain.{<br/>}{<br/>}
              {<br/>}Click UPLOAD button to upload the advertisement{<br/>}{<br/>}
            </span>
          </div>
          <img className="sellerStep1" src={seller} />
          <span>The seller</span>
        </div>
        <div className="flex-container2Step1">
          <div className="rectangle-25Step1">
              <span className="the-seller-will-go-tStep1">
                Before to take the next action please check if your Metamask is ready, enter the Metamask password if necessary, and connect Metamask to your Seller account 
                {<br/>}
                
              </span>
          </div>
          <div className="rectangle-15Step1">
          

            <Notification1 message={wmessage} See={toSee1} tx={tx}></Notification1>
            <div className="rectangle-16Step1">Housing + market place</div>
            <div className="flex-container-1Step1">
              <img className="rectangle-17Step1" src={house} />
              <div className="flex-container-2Step1">
                <div className="rectangle-18Step1">
                  <ListGroup  as="ol" numbered>
                    <ListGroupItem bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive"}}>Reference : </ListGroupItem>
                    <ListGroupItem bsPrefix="h" style={{paddingLeft : '2%', marginBottom:'2%', fontFamily:"cursive", fontSize: "medium" }}>{location}</ListGroupItem>
                    <ListGroup.Item bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive"}}>Price: €700,000 </ListGroup.Item>
                    <ListGroup.Item bsPrefix="h" style={{paddingLeft : '2%', marginBottom:'2%' , fontFamily:"cursive", fontSize: "medium"}}>4 bedroom detached house for sale</ListGroup.Item>
                    <ListGroupItem bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive"}}>Address: </ListGroupItem>
                    <ListGroupItem bsPrefix="h" style={{paddingLeft : '2%', marginBottom:'2%', fontFamily:"cursive", fontSize: "medium" }}>Ings Road, Ulleskelf, Tadcaster, LS24 </ListGroupItem>
                    <ListGroupItem bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive"}}>Description: </ListGroupItem>
                    <ListGroup.Item as="li" bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive", fontSize: "medium"}}>All rooms of excellent proportions</ListGroup.Item>
                    <ListGroup.Item as="li" bsPrefix="h" style={{paddingLeft : '2%' ,fontFamily:"cursive", fontSize: "medium"}}>Impressive reception hall with staircase to galleried landing</ListGroup.Item>
                    <ListGroup.Item as="li" bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive", fontSize: "medium"}}>Two reception rooms and well fitted breakfast kitchen</ListGroup.Item>
                    <ListGroup.Item as="li" bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive", fontSize: "medium"}}>Bedroom one with en-suite dressing room and wet room</ListGroup.Item>
                  </ListGroup>
                </div>
                <Form>
                  <Button  onClick={handleClickUpload}>UPLOAD</Button>
                  <Button button disabled={disabledBtNext} className="next-instance-1Step1" onClick={handleClickNext}>Go to the Next step</Button>
                </Form>
              </div>
            </div>
          </div>
        </div>  
      </div>
    </div>
  );
};
export default Step1;