import React, { useState } from 'react';

import Toast from 'react-bootstrap/Toast';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer } from 'react-bootstrap';

import {useSelector} from "react-redux";

const selectViewNumber = state => state.views.viewTxOne.value;

const selectNotif = state => state.views.notifShow.value;

function Notification1({message, tx, See}) {

  const viewNumber = useSelector(selectViewNumber);
  console.log("view number : ",viewNumber);

  const notShow = useSelector(selectNotif);
  console.log(notShow);


  
  const [showA, setShowA] = useState(true);


  const toggleShowA = () => {if(viewNumber === 3) setShowA(!showA)};

  let title;
  let inFrontTitle; 
  let text; 

  if (viewNumber === 2 ){
    title = "Smart contract is working"
    inFrontTitle = <Spinner animation="border" style={{marginLeft:'5px'}}/>
    text= "The Land Registry smart contract is now called to check if the Seller is registered as the actual owner of this house (and to update this registration if necessary) before to be allowed to sell it. Your Metamask should now pop-up and ask you to confirm that you agree to pay the gas required for this transaction."
  } else if (viewNumber === 3){
    title = "Land Registry Smart Contract validation is done"
    text = `The smart contract sent you this transaction id as proof from the blockchain : ${tx}  You can copy this id and paste it in the search field of the Sepolia etherscan web site (https://sepolia.etherscan.io/) in order to see the details of the transaction.`
  }
  return (

        <ToastContainer position="middle-center" style={{marginLeft : "10%"}}>
            <Toast show={See && showA && notShow} onClose={toggleShowA} bg="warning">
            <Toast.Header>
                <strong className="me-auto"> {title}   {inFrontTitle}</strong>
            </Toast.Header>
            <Toast.Body>{text}</Toast.Body>
            </Toast>
        </ToastContainer>
  );
}

export default Notification1;  