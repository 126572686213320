import { ethers } from 'ethers';
import abi from "./smartContract/saleabi";
import bytecode from "./smartContract/salebytecode";



export default async function deploy2 (paramSigner){
  
    const factory = new ethers.ContractFactory( abi , bytecode.bytecode,  paramSigner );
    const contract = await factory.deploy();

    console.log("Deploy Sale SC=>");
    console.log(contract.address); 

    return (contract.address);
};