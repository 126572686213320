import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import {useHistory } from "react-router-dom";


const View3=()=>{

  const [buyerMessage, setBuyerMessage] = useState("");

  const hisotry = useHistory();
	const handleClick = ()=>{
    localStorage.setItem('buyerMessage', JSON.stringify(buyerMessage))
		hisotry.push("/step4");
	}

  return (
    <Form style={{backgroundColor: "white", width:'82%', borderRadius : '12px', padding : '3%'}}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label><div style={{ fontFamily:"cursive"}} >Email address</div></Form.Label>
        <Form.Control type="email" placeholder="buyer_buyer@market.place"  disabled/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label><div style={{ fontFamily:"cursive"}} >Text Message</div></Form.Label>
        <Form.Control  as="textarea" rows={6} placeholder="Is the house already furnished ?" onChange={(e)=>setBuyerMessage(e.target.value)}/>
      </Form.Group>
      <Button variant="primary" type="submit" onClick={handleClick}>
        SEND
      </Button>
    </Form>
  );
}

export default View3;