import "./Step_71.css";
import house from "../../Media/assets/house.jpg";
import buyer from "../../Media/users/family.png";


import DismissibleToast from "./toast/toast";
import View1 from "./views/view1";
import Text1 from "./views/text/text1";
import Text2 from "./views/text/text2";

import {useSelector} from "react-redux";

const selectViewNumber = state => state.viewNumber.value; 

const Step71 = () => {

  const viewNumber = useSelector(selectViewNumber);
  console.log("view number : ",viewNumber);



  let view;
  let textView;

  if (viewNumber === 1){
    view=<View1></View1>
    textView=<Text1></Text1>
  } else if (viewNumber === 2){
    view=<div></div>
    textView=<Text2></Text2>
  } 

  return (
    <div className="frame-5Step71">
      <div className="cat-absolute-containerStep71">
        <div className="flex-containerStep71">
          <div className="rectangle-13Step71">
            {textView}
          </div>
          <img className="familyStep3" src={buyer} />
          <span>The buyer</span>
        </div>
        <div className="rectangle-15Step71">
          <div className="display-notifStep71">
            <div className="rectangle-16Step71">Housing + market place</div>
            <DismissibleToast></DismissibleToast>
          </div>

          <div className="flex-container-1Step71">
            <img className="rectangle-17Step71" src={house} />
            <div className="flex-container-2Step71">
            {view}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Step71;