

const Text3 = ()=>{
    return(
    <span className="the-buyer-go-to-theStep3 ">
        Type one or many questions to the house owner in the Text Message field.{<br/>}{<br/>}
        When you finish click on the Send button.{<br/>}{<br/>}
    </span>
    )
}

export default Text3;