
import "bootstrap/dist/css/bootstrap.min.css";
import "./Step_14.css";

import { Link, useHistory } from "react-router-dom";
import Actors from "./actors/actors";
import Title from "./title/title";
import { Button } from "react-bootstrap";
import LeftSign from "../Step_10/leftSign/leftSign";
import { strRef, strBck } from "./../../ref";
import { useState, useEffect } from 'react';



const Step14 = () => {

	const hisotry = useHistory();
	const [saleSC, setSaleSC]= useState(" ");
	const [loanSC, setLoanSC]= useState(" ");
	const [mortgageSC, setMortgageSC]= useState(" ");

	const handleClick = ()=>{
		hisotry.push("/step11");
	}

	useEffect(async()=>{
		await handleClickUp();
	});


	const handleClickUp = async ()=>{

		const username = localStorage.getItem("userName")

		const response = await fetch( strBck + 'readSaleSC', {
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json'
			},
			body: JSON.stringify({username:username}),
	  
			});

		const response2 = await fetch( strBck + 'readLoanSC', {
				method: 'POST',
				headers: {
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify({usernameparam:username}),
		  
				}); 	

		const response3 = await fetch( strBck + 'readMortgageSC', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({usernameparam:username}),
		
			}); 	
		
		const respSaleSC = await (response.text())
		const respLoanSC = await (response2.text())
		const respMortgageSC = await (response3.text())
		

		setSaleSC(respSaleSC);
		setLoanSC(respLoanSC);
		setMortgageSC(respMortgageSC);

		//  setBuyerAdd(respBuyerAdd);
		//  setBankerAdd(respBankerAdd);
	}


  return (
    <div className="d-flex flex-row main confetti">
		<LeftSign></LeftSign>
		<div className="d-flex flex-column outlinerHomeComposant">

        <div className="rectangle-25Step12">
              <span >
                Before to take the next action please check if your Metamask is ready, enter the Metamask password if necessary, and connect Metamask to your Buyer account 
                {<br/>}
              </span>
            </div>

			<div>
				{<br/>}
			</div>

			<Title></Title>
			<Actors></Actors>

			<div className="rectangle-25Step2">
				<span className="the-seller-will-go-tStep1">
				Below are listed addresses of all smart contracts created at this state of the process. You can copy any of these addresses and paste it in the search field of the Sepolia etherscan web site (https://sepolia.etherscan.io/) in order to see the details of all corresponding transactions. 
					{<br/>} 
					<b>Address of the SALE Smart Contract: {saleSC}</b>
					{<br/>}
					<b>Address of the LOAN Smart Contract: {loanSC}</b>
					{<br/>}
					<b>Address of the MORTGAGE Smart Contract: {mortgageSC}</b>
				</span>
			</div>
		</div>
    </div>
  );
};

export default Step14;

