import './toast.css';

import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';
import { ToastContainer } from 'react-bootstrap';
import {useDispatch} from "react-redux";

import { Link, useHistory } from "react-router-dom";


import notif from "../../../Media/assets/notification.png"

function DismissibleToast() {

  const dispatch = useDispatch();


  const [showA, setShowA] = useState(false);

  const toggleShowA = () => setShowA(!showA);

  const handleClick1 = ()=>{
    if(showA === false) {
      dispatch({type : 'viewStep7/changeView2'});
      toggleShowA();
    } else if(showA === true){
      dispatch({type : 'viewStep7/changeView1'});
      toggleShowA();
    }
  }

  const hisotry = useHistory();


  const handleClick2 = ()=>{
    dispatch({type : 'viewStep7/changeView1'});
    hisotry.push("/step8");
  }

  let messageSeller = localStorage.getItem('sellerMessage');

  return (
    
      <div>
            <img
              src={notif}
              className="notifImg"
              alt=""
              onClick={handleClick1}
            />
            <ToastContainer position={"middle-end"} style={{marginRight:'10%'}} >
                <Toast show={showA} onClose={handleClick1} >
                <Toast.Header>
                    <strong className="me-auto">Seller</strong>
                    <small>2 mins ago</small>
                </Toast.Header>
                <Toast.Body>{messageSeller}</Toast.Body>
                <Button onClick={handleClick2}>GO TO THE BLOCKCHAIN</Button>
                </Toast>
            </ToastContainer>
      </div>

  );
}

export default DismissibleToast;