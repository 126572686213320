import "./actors.css";
import banker from "../../../Media/users/banker.png";
import family from "../../../Media/users/family.png";

import { Button,  ToastContainer , Toast  } from "react-bootstrap";
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';

//import abi from "../smartContract/LoanSCabi";
import Notification1 from '../notification/notification1';
import Web3 from 'web3';
import { useState } from 'react';

import { ethers } from 'ethers';

import {useSelector ,useDispatch} from "react-redux";
import Notification2 from "../notification/notification2";

import { Link, useHistory } from 'react-router-dom';



const selectViewNumber = state => state.views; 

const Actors = () => {

  const dispatch = useDispatch();

  const { width, height } = useWindowSize();

  const state = useSelector(selectViewNumber);
  console.log("state : ", state);
  const viewNumber1 = state.viewTxOne.value; 
  const viewNumber2 = state.viewTxTwo.value;
  const notifShow1 = state.notifShow1.value;
  const notifShow2 = state.notifShow2.value;

  console.log("view number : ",viewNumber1);

  const [tx, setTx] = useState();
  const [message, setMessage]= useState();
  

  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

 // const testContract = new ethers.Contract('0x610bCdB4eA920219F07DFb2821efa99d0B573E25',abi, signer);

  const hisotry = useHistory();


  const handleClickSignLoan = async ()=>{

    dispatch({type : 'txOne/whileTX'});

  /*  const response = await fetch(`http://localhost:5000/readLoanSC`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({"username":"Thierry"}),
    });
  
    console.log("TGH25: ", response);

    const transaction_payload= await testContract.increment();

    console.log('transaction response : ', transaction_payload);
    setTx(transaction_payload.hash); */

    dispatch({type : 'txOne/afterTX'});
  }

  let checkBoolean1;
  let disabledBoolean1;
  let toSee1;
  let ButtonStyle1;
  let ButtonText1;

  if (viewNumber1 === 1 ){
    checkBoolean1 = false;
    disabledBoolean1 = false;
    toSee1 = false;
    ButtonStyle1 = "secondary";
    ButtonText1 = "Sign Loan Contract";
  } else if ( viewNumber1 === 2){
    checkBoolean1 = false;
    disabledBoolean1 = false;
    toSee1 = true;
    ButtonStyle1 = "secondary";
    ButtonText1 = "Sign Loan Contract";
  } else if (viewNumber1 === 3){
    checkBoolean1 = true;
    disabledBoolean1 = true;
    toSee1 = true;
    ButtonStyle1 = "light";
    ButtonText1 = "Loan Contract Signed";
  }

  const handleClickSignMortgage = async ()=>{

  /*  const username = localStorage.getItem("userName")
    const response2 = await fetch(`http://localhost:5000/readLoanSC`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
    },
      body: JSON.stringify({"usernameparam":username}),
    }) ;

    const resLoanSC = await (response2.text())

    const loanContract = new ethers.Contract(resLoanSC, abi, signer);
    const transaction_payload= await loanContract.signMortgageCreation();

    console.log("TGH 52 loan sc:", resLoanSC)



 /*   dispatch({type : 'txTwo/whileTX'});

    const transaction_payload= await testContract.increment();

    console.log('transaction response : ', transaction_payload);

    setTx(transaction_payload.hash);

    dispatch({type : 'txTwo/afterTX'});*/
  }

  const handleClickNext = async ()=>{

    
    hisotry.push("/step13");


  }


  let checkBoolean2;
  let disabledBoolean2;
  let toSee2;
  let ButtonStyle2;
  let ButtonText2;

  if (viewNumber2 === 1 ){
    checkBoolean2 = false;
    disabledBoolean2 = false;
    toSee2 = false;
    ButtonStyle2 = "secondary";
    ButtonText2 = "Authorize Mortgage";
  } else if ( viewNumber2 === 2){
    checkBoolean2 = false;
    disabledBoolean2 = false;
    toSee2 = true;
    ButtonStyle2 = "secondary";
    ButtonText2 = "Authorize Mortgage";
  } else if (viewNumber2 === 3){
    checkBoolean2 = true;
    disabledBoolean2 = true;
    toSee2 = true;
    ButtonStyle2 = "light";
    ButtonText2 = "Mortgage Authorized";
  }


  

  return (
    <div className="containerStep12">
      <Notification1 See={toSee1} tx={tx}></Notification1>
      <div className="container-3Step12 ">
        <img className="image-2Step12" src={banker} />
        <span className="text-2Step17">The Banker</span>
        
      </div>
      <Notification2 See={toSee2} tx={tx}></Notification2>
      <div className="container-4Step12">
        <img className="image-3Step12" src={family} />
        <span className="text-3Step12">The Buyer</span>
       
        
      </div>

      <div className="container-3Step13 ">
        
       
        
      </div>

      <div className="containerRightStep12">
      <Confetti width={width} height={height}/>
      <ToastContainer position="middle-center" style={{marginLeft : "15%"}}>
            <Toast show={true}  bg="primary">
            <Toast.Header>
                <strong className="me-auto">THE END</strong>
            </Toast.Header>
            <Toast.Body>
                  i.	The money is disbursed to the seller by the bank{<br/>}{<br/>}
                  ii.	The mortgage is recorded in the sheet of the property in the national land registry and the first installment is due by the buyer. {<br/>}{<br/>}
                  iii.	Taxes are paid to the State for the mortgage and the sales contract. {<br/>}{<br/>}
                  {<br/>}{<br/>}
            </Toast.Body>
            </Toast>
        </ToastContainer>

      </div> : <div className="containerRightStep12"></div>
    </div>
  );
};
export default Actors;