import "./title.css";
import hLogo from "../../../Media/logo-compost-800px.jpg";

/*return <div className="outlinerTextTitle d-flex justify-content-center"><span className="textTitle">Housing + </span></div>;*/

const Title = () => {
  return <div className="outlinerTextTitle d-flex justify-content-center"><img src={hLogo} /></div>;
};

export default Title;
