import "./actors.css";
import seller from "../../../Media/users/seller.png";
import banker from "../../../Media/users/banker.png";
import family from "../../../Media/users/family.png";
import state from "../../../Media/users/state.png";

import Button from "./button/button";

const Actors = () => {
  return (
    <div className="containerStep0">
      <div className="container-1Step0">
        <img className="imageStep0" src={banker} />
        <span className="textStep0">The banker</span>
      </div>
      <div className="container-2Step0">
        <img className="image-1Step0" src={state} />
        <span className="text-1Step0">The state</span>
      </div>
      <div className="container-3Step0 ">
        <img className="image-2Step0" src={seller} />
        <span className="text-2Step0">The seller</span>
      </div>
      <div className="container-4Step0">
        <img className="image-3Step0" src={family} />
        <span className="text-3Step0">The buyer</span>
      </div>
    </div>
  );
};
export default Actors;