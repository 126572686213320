import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import abiRegister from '../smartContract/abiRegister';
import abiMortgageRegister from '../smartContract/abiMortgageRegister';
import Notification1 from '../notification/notification1';
import Web3 from 'web3';
import { useState } from 'react';

import { ethers } from 'ethers';

import {useSelector ,useDispatch} from "react-redux";
import Notification2 from '../notification/notification2';
import Notification3 from '../notification/notification3';

import deploy from './deploySale';
import deploy2 from './deploySale2';



const selectViewNumber = state => state.views; 


function CheckExample() {

  const dispatch = useDispatch();

  const state = useSelector(selectViewNumber);
  console.log("state : ", state);
  const viewNumber1 = state.viewTxOne.value; 
  const viewNumber2 = state.viewTxTwo.value;
  const viewNumber3 = state.viewTxThree.value;

  console.log("view number : ",viewNumber1);

  const [tx, setTx] = useState();
  const [message, setMessage]= useState();
  

  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const testContract = new ethers.Contract('0xe23390d2FF6493103F2188F46C6C8160E0920594',abiRegister, signer);
  const mortgageRegisterContract = new ethers.Contract('0xb2FE130bfd14Dd86E67D5B2a70f26E72fBa0DE44',abiMortgageRegister, signer);

  // Check Smart Contract Land registry
  const handleClick1 = async ()=>{

    dispatch({type : 'txOne/whileTX'});

    const transaction_payload= await testContract.askOwnerAdd("TGH");

    console.log('transaction response : ', transaction_payload);

    //setTx(transaction_payload.hash);
    setTx(transaction_payload);

    dispatch({type : 'txOne/afterTX'});
  }

  let checkBoolean1;
  let disabledBoolean1;
  let toSee1;
  let ButtonStyle1;
  let ButtonText1;

  if (viewNumber1 === 1 ){
    checkBoolean1 = false;
    disabledBoolean1 = false;
    toSee1 = false;
    ButtonStyle1 = "secondary";
    ButtonText1 = "Check Land Registry Smart Contract";
  } else if ( viewNumber1 === 2){
    checkBoolean1 = false;
    disabledBoolean1 = false;
    toSee1 = true;
    ButtonStyle1 = "secondary";
    ButtonText1 = "Check Land Registry Smart Contract";
  } else if (viewNumber1 === 3){
    checkBoolean1 = true;
    disabledBoolean1 = true;
    toSee1 = true;
    ButtonStyle1 = "light";
    ButtonText1 = "Check Mortgage Registry Smart Contract";
  }

  // Secong check
  const handleClick2 = async ()=>{

   // dispatch({type : 'txTwo/whileTX'});

   // const transaction_payload= await testContract.increment();

   // console.log('transaction response : ', transaction_payload);

   // setTx(transaction_payload.hash);

   // dispatch({type : 'txTwo/afterTX'});
  }

  /*let checkBoolean2;
  let disabledBoolean2;
  let toSee2;
  let ButtonStyle2;
  let ButtonText2;

  if (viewNumber2 === 1 ){
    checkBoolean2 = false;
    disabledBoolean2 = false;
    toSee2 = false;
    ButtonStyle2 = "secondary";
    ButtonText2 = "CHECK";
  } else if ( viewNumber2 === 2){
    checkBoolean2 = false;
    disabledBoolean2 = false;
    toSee2 = true;
    ButtonStyle2 = "secondary";
    ButtonText2 = "CHECK";
  } else if (viewNumber2 === 3){
    checkBoolean2 = true;
    disabledBoolean2 = true;
    toSee2 = true;
    ButtonStyle2 = "light";
    ButtonText2 = "CHECKED";
  }*/


  const handleClick3 = async ()=>{
  
  // reprendre deploy2 pour deploiement contrat vente et mortgage  
  //  await deploy2(signer);

 /*   dispatch({type : 'txThree/whileTX'});

    const transaction_payload= await testContract.increment();

    console.log('transaction response : ', transaction_payload);
    setTx(transaction_payload.hash);

    dispatch({type : 'txThree/afterTX'});*/

    dispatch({type : 'txThree/whileTX'});

    const transaction_payload= await mortgageRegisterContract.checkRegister("TGH");

    console.log('transaction response : ', transaction_payload);

    //setTx(transaction_payload.hash);
    setTx(transaction_payload);

    dispatch({type : 'txThree/afterTX'});
  }

  let checkBoolean3;
  let disabledBoolean3;
  let toSee3;
  let ButtonStyle3;
  let ButtonText3;

  if (viewNumber3 === 1 ){
    checkBoolean3 = false;
    disabledBoolean3 = false;
    toSee3 = false;
    ButtonStyle3 = "secondary";
    ButtonText3 = "Check Mortgage Registry Smart Contract";
  } else if ( viewNumber3 === 2){
    checkBoolean3 = false;
    disabledBoolean3 = false;
    toSee3 = true;
    ButtonStyle3 = "secondary";
    ButtonText3 = "Check Mortgage Registry Smart Contract";
  } else if (viewNumber3 === 3){
    checkBoolean3 = true;
    disabledBoolean3 = true;
    toSee3 = true;
    ButtonStyle3 = "light";
    ButtonText3 = "Mortgage Smart Contract Checked";
  }


  return (
    <Form>
               <Notification1 See={toSee1} tx={tx}></Notification1>
               
               <Notification3 See={toSee3} tx={tx}></Notification3>               
        <div key="default-checkbox" className="mb-3">

          <div style={{marginBottom : '5%'}}>
            <Form.Check 
              type="checkbox"
              id="default-checkbox"
              label="The seller really own the proprety and the proprety is registered in the land registry"
              checked={checkBoolean1}
            />
            <Button 
              variant={ButtonStyle1}
              style={{marginLeft : '40%'}} 
              onClick={handleClick1} 
              disabled ={disabledBoolean1}>{ButtonText1}</Button>
          </div>

         

          <div style={{marginBottom : '5%'}}>
            <Form.Check 
              type="checkbox"
              id="default-checkbox"
              label="The property should not be already mortgaged or has other burdens"
              checked={checkBoolean3}
            />
            <Button 
              variant={ButtonStyle3}
              style={{marginLeft : '40%'}} 
              onClick={handleClick3} 
              disabled ={disabledBoolean3}>{ButtonText3}</Button>
          </div>

        </div>
    </Form>
  );
}

export default CheckExample;