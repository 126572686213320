import "./actors.css";

import seller from "../../../Media/users/seller.png";
import banker from "../../../Media/users/banker.png";
import family from "../../../Media/users/family.png";
import state from "../../../Media/users/state.png";



const Actors = () => {
  return (
    <div className="containerStep4">
      <div className="container-1Step4">
        <img className="imageStep4" src={banker} />
        <span className="textStep4">The banker</span>
      </div>
      <div className="container-2Step4">
        <img className="image-1Step4" src={state} />
        <span className="text-1Step4">The state</span>
      </div>
      <div className="container-3Step4 ">
        <img className="image-2Step4" src={seller} />
        <span className="text-2Step4">The seller</span>
      </div>
      <div className="container-4Step4">
        <img className="image-3Step4" src={family} />
        <span className="text-3Step4">The buyer</span>
      </div>
    </div>
  );
};
export default Actors;