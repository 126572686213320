import "./button.css";

import { useHistory } from "react-router-dom";

const Button = ({className, registerProprety}) => {

    const hisotry = useHistory();
	const handleClick = ()=>{
		hisotry.push("/step0");
	}

  return (
    <button className={`buttonInLeftSign ${className || ""}`} onClick={handleClick}>
      <span className="textInLeftSign ">{registerProprety || "NEXT"}</span>
    </button>
  );
};
export default Button;