import house from "../../../Media/assets/house.jpg";
import { Button } from "react-bootstrap";
import {useDispatch} from 'react-redux';
import { useState, useEffect } from 'react';

import ListGroup from 'react-bootstrap/ListGroup';
import { strRef, strBck } from "../../../ref";




const View2 =()=>{

    const [location, setLocation]= useState("Test Ref");  

    const dispatch = useDispatch();
    const handleClick = ()=>{
      dispatch({type : 'viewStep3/changeView3'})
    }

    useEffect ( async () => {

      const username = localStorage.getItem("userName")

      console.log("TGH361")

      const response = await fetch( strBck + 'readLocationRef', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({usernameparam:username}),

        });

      const respRef = await (response.text())    

      console.log('TG 360: ',respRef)
      setLocation(respRef)
      
    }, []);

    return(          
        <div className="flex-container-1Step1">
            <img className="rectangle-17Step1" src={house} />
            <div className="flex-container-2Step1">
            <div className="rectangle-18Step1">
                <ListGroup  as="ol" numbered>
                <ListGroup.Item bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive"}}>Reference : </ListGroup.Item>
                  <ListGroup.Item bsPrefix="h" style={{paddingLeft : '2%', marginBottom:'2%', fontFamily:"cursive", fontSize: "medium" }}>{location}</ListGroup.Item>
                  <ListGroup.Item bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive"}}>€700,000 </ListGroup.Item>
                  <ListGroup.Item bsPrefix="h" style={{paddingLeft : '2%', marginBottom:'2%' , fontFamily:"cursive", fontSize: "medium"}}>4 bedroom detached house for sale</ListGroup.Item>
                  <ListGroup.Item bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive"}}>Address: </ListGroup.Item>
                  <ListGroup.Item bsPrefix="h" style={{paddingLeft : '2%', marginBottom:'2%', fontFamily:"cursive", fontSize: "medium" }}>Ings Road, Ulleskelf, Tadcaster, LS24 </ListGroup.Item>
                  <ListGroup.Item bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive"}}>Description: </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive", fontSize: "medium"}}>All rooms of excellent proportions</ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="h" style={{paddingLeft : '2%' ,fontFamily:"cursive", fontSize: "medium"}}>Impressive reception hall with staircase to galleried landing</ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive", fontSize: "medium"}}>Two reception rooms and well fitted breakfast kitchen</ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive", fontSize: "medium"}}>Bedroom one with en-suite dressing room and wet room</ListGroup.Item>
                </ListGroup>
            </div>
            <Button className="next-instance-1Step1" onClick={handleClick}>ASK A QUESTION</Button>
            </div>
        </div>
  )
}

export default View2