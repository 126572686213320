import "./actors.css";
import seller from "../../../Media/users/seller.png";
import banker from "../../../Media/users/banker.png";
import family from "../../../Media/users/family.png";
import state from "../../../Media/users/state.png";

const Actors = () => {
  return (
    <div className="container">
      <div className="container-1">
        <img className="image" src={banker} />
        <span className="text">The banker</span>
      </div>
      <div className="container-2">
        <img className="image-1" src={state} />
        <span className="text-1">The state</span>
      </div>
      <div className="container-3">
        <img className="image-2" src={seller} />
        <span className="text-2">The seller</span>
      </div>
      <div className="container-4">
        <img className="image-3" src={family} />
        <span className="text-3">The buyer</span>
      </div>
    </div>
  );
};
export default Actors;