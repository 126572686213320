import "./leftSign.css";


const LeftSign = () => {

  return (
    <div className="outlinerLeftSignStep2">

    </div>
  );
};
export default LeftSign;
