

const initialState = {
    views : {
        viewNumber: {
            value : 1
        },
        viewTx: {
            value: 1
        }
    }
  }
  
  // Use the initialState as a default value
const appReducer = (state = initialState, action)=>{
    // The reducer normally looks at the action type field to decide what happens
    switch (action.type) {
      // Do something here based on the different types of actions
      case 'viewStep9/changeView2':
          return{
            views: {
                ...state.views,
                viewNumber :{
                    value : 2
                }
            }
          }
      case 'viewStep9/changeView3':
          return{
            views: {
                ...state.views,
                viewNumber :{
                    value : 3
                }
            }
          }
      case 'tx/beforeTX':
        return{
            views : {
                ...state.views,
                viewTx :{
                    value : 1
                }
            }
        }
      case 'tx/whileTX':
            return{
                views : {
                    ...state.views,
                    viewTx :{
                    value : 2
                 }
                }
            }

      case 'tx/afterTX':
        return{
            views:{
                ...state.views,
                viewTx :{
                value : 3
                }
            }
        }
      default:
        // If this reducer doesn't recognize the action type, or doesn't
        // care about this specific action, return the existing state unchanged
        return state
    }
  }

export default appReducer;