import ListGroup from 'react-bootstrap/ListGroup';


const View1 = ()=>{
    return(
    <div className="rectangle-18Step1">
                <ListGroup  as="ol" numbered>
                  <ListGroup.Item bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive"}}>€700,000 </ListGroup.Item>
                  <ListGroup.Item bsPrefix="h" style={{paddingLeft : '2%', marginBottom:'2%' , fontFamily:"cursive", fontSize: "medium"}}>4 bedroom detached house for sale</ListGroup.Item>
                  <ListGroup.Item bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive"}}>Address: </ListGroup.Item>
                  <ListGroup.Item bsPrefix="h" style={{paddingLeft : '2%', marginBottom:'2%', fontFamily:"cursive", fontSize: "medium" }}>Ings Road, Ulleskelf, Tadcaster, LS24 </ListGroup.Item>
                  <ListGroup.Item bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive"}}>Description: </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive", fontSize: "medium"}}>All rooms of excellent proportions</ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="h" style={{paddingLeft : '2%' ,fontFamily:"cursive", fontSize: "medium"}}>Impressive reception hall with staircase to galleried landing</ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive", fontSize: "medium"}}>Two reception rooms and well fitted breakfast kitchen</ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix="h" style={{paddingLeft : '2%', fontFamily:"cursive", fontSize: "medium"}}>Bedroom one with en-suite dressing room and wet room</ListGroup.Item>
                </ListGroup>
   </div>
  )
}



export default View1;