

const Text1 = ()=>{
    return(
        <span className="the-seller-will-go-tStep5">
        Check your notifications for your house advertisement.{<br/>}{<br/>}Click Notification button{<br/>}{<br/>}
      </span>
    )
}

export default Text1;