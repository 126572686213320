import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {useDispatch, useSelector} from 'react-redux';





const selectViewNumber = state => state.views.viewTx.value; 


const View1=()=>{

  const [loan, setLoan] = useState("700000");



  const txState = useSelector(selectViewNumber);

  const dispatch = useDispatch();

  const handleClick = async ()=>{
    localStorage.setItem('loanAmount', loan)
    dispatch({type : 'viewStep9/changeView2'})
  }

  let toSee;

  if (txState === 1 ){
    toSee = true;
  } else if ( txState === 2){
    toSee = true;
  } else if (txState === 3){
    toSee = true;
  }



  return (
    <Form style={{backgroundColor: "white", width:'100%', borderRadius : '12px', padding : '3%'}}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label><div style={{fontFamily:"cursive"}}>Loan amount €</div></Form.Label>
        <Form.Control type="textarea" placeholder="700,000 €" onChange={(e)=>{setLoan(e.target.value)}}/>
        <Form.Text className="text-muted">
          Enter the amount of money that you need to buy the proprety.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label><div style={{fontFamily:"cursive"}}>Loan Reason</div></Form.Label>
        <Form.Select aria-label="Default select example">
          <option>Why you are applying for a loan ? </option>
          <option value="1">To buy a house</option>
          <option value="2">To buy a car</option>
          <option value="3">Personal loan</option>
        </Form.Select>
      </Form.Group>
      <Button variant="primary" type="submit" onClick={handleClick}>
        SUBMIT
      </Button>
    </Form>
  );
}

export default View1;