import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import bank from "../../../Media/assets/bank.jpg";

import { useDispatch } from 'react-redux';

function BasicCard({isDisabled, title, text}) {

  const dispatch = useDispatch();
  const handleClick = ()=>{
    dispatch({type : 'viewStep9/changeView3'})
  }
  return (
    <Card style={{ width: '100%'}}>
      <Card.Img variant="top" src={bank} />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{text}</Card.Text>
        <Button variant="primary" disabled={isDisabled} onClick={handleClick}>More Details</Button>
      </Card.Body>
    </Card>
  );
}

export default BasicCard;