


import "./title.css";
const Title = () => {
  return (
    <div className="outlinerStep4Title">
      <div className="inlinerStep4Title">
      <span className="textStep4Title">
          The Loan smart contract is ready.{<br/>}{<br/>}
          If the buyer agrees with the proposal he needs to sign it.
        </span>
      </div>
    </div>
  );
};
export default Title;