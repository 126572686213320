import "./leftSign.css";
import Button from "./button/button";

const LeftSign = () => {
  const propsData = {
    next: {
        registerProprety: "NEXT",
    },
};
  return (
    <div className="outlinerLeftSign">
      <div className="inlinerLeftSign">
        <span className="textLeftSign">
          This demo will guide you step by step
          to see all the stages of a real state purchase based on smart
          contracts deployed on the blockchain. {<br/>}{<br/>} Click on the button to begin the demo !{<br/>}{<br/>} 
        </span>
      </div>
      <Button className="buttonLeftSign" {...propsData.next} />
    </div>
  );
};
export default LeftSign;
