

const Text2 = ()=>{
    return(
    <span className="the-buyer-go-to-theStep3 ">
        Now you can see some general information about the house , the price, the address and how many rooms in it.
        {<br/>}If you want to get more details, you can ask the house owner. {<br/>}{<br/>} Click on ASK A QUESTION.  
    </span>
    )
}

export default Text2;