import './toast.css';

import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';
import { ToastContainer } from 'react-bootstrap';
import {useDispatch} from "react-redux";


import notif from "../../../Media/assets/notification.png"

function DismissibleToast() {

  const dispatch = useDispatch();


  const [showA, setShowA] = useState(false);

  const toggleShowA = () => setShowA(!showA);

  const handleClick1 = ()=>{
    if(showA === false) {
      dispatch({type : 'viewStep5/changeView2'});
      toggleShowA();
    } else if(showA === true){
      dispatch({type : 'viewStep5/changeView1'});
      toggleShowA();
    }
  }

  const handleClick2 = ()=>{
    if(showA === false) {
      dispatch({type : 'viewStep5/changeView1'});
      toggleShowA();
    } else if(showA === true){
      dispatch({type : 'viewStep5/changeView3'});
      toggleShowA();
    }
  }

  let messageBuyer = localStorage.getItem('buyerMessage');

  return (
    
      <div>
            <img
              src={notif}
              className="notifImg"
              alt=""
              onClick={handleClick1}
            />
            <ToastContainer position={"middle-end"} style={{marginRight:'10%'}} >
                <Toast show={showA} onClose={handleClick1} >
                <Toast.Header>
                    <strong className="me-auto">Buyer</strong>
                    <small>3 mins ago</small>
                </Toast.Header>
                <Toast.Body>{messageBuyer}</Toast.Body>
                <Button onClick={handleClick2}>Reply</Button>
                </Toast>
            </ToastContainer>
      </div>

  );
}

export default DismissibleToast;