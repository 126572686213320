import React, { useState, useEffect } from "react";
import { Form, Button, Container, Alert, Col } from "react-bootstrap";
import {  useHistory } from "react-router-dom";
import { strRef, strBck } from "./../../ref";
import hLogo from "../../Media/assets/LogoEHS.jpg";


//const localUsername = process.env.REACT_APP_USERNAME; 
//const localPasssword = process.env.REACT_APP_PASSWORD; 

const localUsername = ""; 
const localPasssword = ""; 

const LoginPage = () => {


  const hisotry = useHistory();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [haveMetamask, setHaveMetamask]= useState(true)

  const metamask = window?.ethereum 
  useEffect(() => {
  
  setHaveMetamask(metamask ? true : false)
  },[metamask])

  const handleClickLogin = async (event) => {

    if (!haveMetamask) {
     alert("Metamask not found, you can't login.")
     return;
    }
    event.preventDefault();

    console.log("TGH30 localusername: ", localUsername);
    console.log(localPasssword);

    try {

    /*  const username = localStorage.getItem("userName")
      console.log("TGH31", username);
      const password = localStorage.getItem("password")
      console.log("TGH32", password); */


      console.log("TGH31 username: ", username);

      const response = await fetch(strBck + 'login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({username:username,password:password}),

      });
  
      //console.log("TGH33: ",( await response.text()));
      const respPassword = await (response.text());

      if( respPassword ==="true"){
        console.log(username);
        localStorage.setItem('userName', username);
    
        hisotry.push("/home");
      }else {
        let e = new Error(" Username or password is missing ");
        console.error(e);
        setError("Incorrect user name or password");
      }


    } catch (error) {
      console.error(error);
      setError("Incorrect user name or password");
    }
  };

  const handleClickRegister = async (event) => {
    hisotry.push("/register");
  };  

  useEffect(() => {
    // const userName = localStorage.getItem('userName');
    // const userPassword = localStorage.getItem('password');
    //if (userName == localUsername && userPassword == localPasssword) {
        //hisotry.push("/home");
    //}
  }, []);

  
  let disabledBoolean1;
  let ButtonStyle1;
  let ButtonText1;

  disabledBoolean1 = false;
  ButtonStyle1 = "secondary";
  ButtonText1 = "Register";

  return (
    <Container className="my-5" style={{width : "50%", marginLeft : "25%"}}>
      <Col>
      <img src={hLogo} />
      <h1 className="text-center">Login</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      {!haveMetamask && ( <Alert variant="danger">Metamask not found</Alert>)}

      <Form onSubmit={handleClickLogin} >
        <Form.Group controlId="formBasicEmail">
          <Form.Label>username</Form.Label>
          <Form.Control
            type="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Form.Group>
        
     
        <br></br>
      

      <table cellpadding="0" cellspacing="0" width="100%" border="0" >
          <tr>
            <th width="50%">
              <td class="align-center">
                <Button type="submit" variant="primary" type="submit">
                  Login
                </Button>
              </td>  
            </th>  
            <th width="50%">
              <td class="align-center">
                <Button onClick={handleClickRegister} variant="primary" type="submit">
                  Register a new account
                </Button>
              </td>
            </th>
           
          </tr>
        </table>  
        </Form>  
        </Col>

    </Container>
  );
};

export default LoginPage;

