

const Text3 = ()=>{
    return(
    <span className="the-buyer-go-to-theStep3 ">
        This is the offer that you got from the banker{<br/>}{<br/>}
        Read carefully the details of the offer.{<br/>}{<br/>}
        Submit your interest if you want to get this loan.{<br/>}{<br/>}
    </span>
    )
}

export default Text3;