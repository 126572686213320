


import "./title.css";
const Title = () => {
  return (
    <div className="outlinerStep4Title">
      <div className="inlinerStep4Title">
      <span className="textStep4Title">
          The sale has been concluded so the Bank can now pay the Seller with the money loaned by the buyer.{<br/>}{<br/>}
          
        </span>
      </div>
    </div>
  );
};
export default Title;