

const Text1 = ()=>{
    return(
        <span className="the-seller-will-go-tStep71">
        Check your notifications, you got a message from the seller.{<br/>}{<br/>}Click Notification button{<br/>}{<br/>}
      </span>
    )
}

export default Text1;