

const Text1 = ()=>{
    return(
    <span className="the-buyer-go-to-theStep3 ">
        The buyer go to the market place and search for a house to buy. {<br/>}
        In the market place he found a portfolio of houses.{<br/>}{<br/>}
        To see more details about the house posted by the seller, click on More Details  
    </span>
    )
}

export default Text1;