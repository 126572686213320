


import "./title.css";
const Title = () => {
  return (
    <div className="outlinerStep4Title">
      <div className="inlinerStep4Title">
      <span className="textStep4Title">
          Now , the banker will issue a formal mortgage loan contract . {<br/>}{<br/>}
          Choose the banker to go forward.
        </span>
      </div>
    </div>
  );
};
export default Title;