import React, { useState, useEffect } from "react";
import { Form, Button, Container, Alert, Col } from "react-bootstrap";
import {  useHistory } from "react-router-dom";
import { strRef, strBck } from "./../../ref";
import hLogo from "../../Media/assets/LogoEHS.jpg";

//const localUsername = process.env.REACT_APP_USERNAME; 
//const localPasssword = process.env.REACT_APP_PASSWORD; 

const localUsername = "Tierry"; 
const localPasssword = ""; 

const LoginPage = () => {


  const hisotry = useHistory();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

 
  const [haveMetamask, setHaveMetamask]= useState(true)
  const handleClickLogin = ()=>{

    ///dispatch({type : 'notif/show'});
		hisotry.push("/login");
	}  

  const handleRegister = async(event) => {
    event.preventDefault();
  
    if (!haveMetamask) {
      alert("Metamask not found, you can't register. Please use an up to date version of mozilla Firefox. ")
      return;
     }
    try {

     const locationref = "Location: " + username + " " + Math.floor(Math.random() * 100)
     console.log("TG1: ",JSON.stringify({ username, password, email, locationref }));
     
     const response = await fetch(strBck + `register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      
      body: JSON.stringify({ username, password, email, locationref }),
    });
  

  
  
    let bodyst = await response.text()
    if (bodyst.indexOf("exist") >= 0) {
      setError(bodyst)


      
      return;
    }
    let data = JSON.parse(bodyst)
    console.log( data) ;
    // process.env.REACT_APP_USERNAME = username; 
    hisotry.push("/login");
    
    } 
    catch (error) {
      console.log("ERROR"+error);

      setError(error);
    }
    
    };

  useEffect(() => {
    const userName = localStorage.getItem('userName');
    const userPassword = localStorage.getItem('password');
    if (userName == localUsername && userPassword == localPasssword) {
        //hisotry.push("/home");
    }
  }, []);

  const metamask = window?.ethereum 
  useEffect(() => {
  
  setHaveMetamask(metamask ? true : false)
  },[metamask])
  
  let disabledBoolean1;
  let ButtonStyle1;
  let ButtonText1;

  disabledBoolean1 = false;
  ButtonStyle1 = "secondary";
  ButtonText1 = "Register";
  return (
    
   
    <Container className="my-5" style={{width : "50%", marginLeft : "25%"}}>
      <Col>
      
      <img src={hLogo} />
      <h4 className="text-center" style={{ color: "red" }}>Developed and tested with Mozilla Firefox</h4>

      <h1 className="text-center">Register a new account</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      {!haveMetamask && ( <Alert variant="danger">Metamask not found. Please </Alert>)}
      <Form onSubmit={handleRegister} >
        <Form.Group controlId="formBasicEmail">
          <Form.Label>username</Form.Label>
          <Form.Control
            type="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>email</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Form.Group>
        <br></br>

        <table cellpadding="0" cellspacing="0" width="100%" border="0" >
          <tr>
            <th width="50%">
              <td class="align-center">
                <Button variant="primary" type="submit">
                  Register
                </Button>
              </td>
            </th>
            <th width="50%">
              <td class="align-center">
                <Button onClick={handleClickLogin} variant="primary" type="submit">
                  Login with an existing account
                </Button>
              </td>  
            </th>  
          </tr>
        </table>  

       
        <br></br>
        <br></br>

        
      </Form>
     
      </Col>
    </Container>
  
  );
};

export default LoginPage;

