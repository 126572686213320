import React, { useState } from 'react';

import Toast from 'react-bootstrap/Toast';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer } from 'react-bootstrap';

import {useSelector} from "react-redux";

const selectViewNumber = state => state.views.viewTxThree.value; 

const selectNotif = state => state.views.notifShow.value;

function Notification3({message, tx, See}) {

  const viewNumber = useSelector(selectViewNumber);
  console.log("view number : ",viewNumber);

  const notShow = useSelector(selectNotif);
  console.log(notShow);

  
  const [showA, setShowA] = useState(true);


  const toggleShowA = () => {if(viewNumber === 3) setShowA(!showA)};

  let title;
  let inFrontTitle; 
  let text; 
  let testResp = `Resp : ${tx}`;

  if (viewNumber === 2 ){
    title = "Smart contract is working"
    inFrontTitle = <Spinner animation="border" />
    text= "The smart contract is checking the land registry"
  } else if (viewNumber === 3){
    title = "Mortgage Registry Smart contract validation is done"

    
    if(testResp == "Resp : false"){
      text = `The smart contract didn't found any mortgage for this building`
    }else{
      text = `The smart contract sent you this transaction id as proof from the blockchain : ${tx}`
      //text = testResp
    }
  }
  return (

        <ToastContainer position="middle-center" style={{marginLeft : "10%"}}>
            <Toast show={See && showA && notShow } onClose={toggleShowA} bg="warning">
            <Toast.Header>
                <strong className="me-auto"> {title}   {inFrontTitle}</strong>
            </Toast.Header>
            <Toast.Body>{text}</Toast.Body>
            </Toast>
        </ToastContainer>
  );
}

export default Notification3;  