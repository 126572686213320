import house from "../../../Media/assets/house.jpg";
import house2 from "../../../Media/assets/house2.jpg";
import house3 from "../../../Media/assets/house3.jpg";

import BasicCard from "./../Card/Card";

const View1 = ()=>{

    return (
        <div className="flex-container-1Step3 ">
            <div className="rectangle-19Step3 "><BasicCard houseIMG={house} title="House" text="Ings Road, Ulleskelf, Tadcaster, LS24"></BasicCard></div>
            <div className="rectangle-20Step3 "><BasicCard houseIMG={house2} isDisabled="true" title="Appartement 1" text="Av. De La Gare, Esch-sur-alzette, Luxembourg"></BasicCard></div>
            <div className="rectangle-21Step3 "><BasicCard houseIMG={house3} isDisabled="true" title="Appartement 2" text="Henan Cortes 44, Móstoles, Madrid, Spain" ></BasicCard></div>
        </div>
    )
}

export default View1;