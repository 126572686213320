

const Text2 = ()=>{
    return(
    <span className="the-buyer-go-to-theStep3 ">
        Now the buyer got some loan offers from different banks.{<br/>}{<br/>}
        He could check the details of each offer and choose the good offer for him. {<br/>}{<br/>} 
        Click on MORE DETAILS to get more details about the loan offer.  
    </span>
    )
}

export default Text2;