


import "./title.css";
const Title = () => {
  return (
    <div className="outlinerStep2Title">
      <div className="inlinerStep2Title">
        <span className="textStep2Title">
          Now the buyer will check the seller responses.{<br/>}
          Choose the buyer role to go to the next step
        </span>
      </div>
    </div>
  );
};
export default Title;